<template>
  <div
      class="modal fade bd-example-modal-lg"
      id="modalVerify"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="d-flex justify-content-end bg-white border-20px">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            data-dismiss="modal"
            aria-label="Close"
            class="mt-3 mr-3 cursor-pointer"
            >
            <path d="M6 17.9998L17.9998 6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.9998 17.9998L6 6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="modal-body">
          <form @submit.prevent="onUpdateKyc">
            <div class="row container px-4">
              <div class="col-md-4 d-flex align-items-center">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                      width="48"
                      height="48"
                      rx="24"
                      fill="#20409B"
                  />
                  <path
                      d="M34 15H14C12.9 15 12 15.9 12 17V31C12 32.1 12.9 33 14 33H34C35.1 33 35.99 32.1 35.99 31L36 17C36 15.9 35.1 15 34 15ZM34 31H14V17H34V31ZM33 18H26V23H33V18ZM32 20L29.5 21.75L27 20V19L29.5 20.75L32 19V20ZM21 24C22.65 24 24 22.65 24 21C24 19.35 22.65 18 21 18C19.35 18 18 19.35 18 21C18 22.65 19.35 24 21 24ZM21 20C21.55 20 22 20.45 22 21C22 21.55 21.55 22 21 22C20.45 22 20 21.55 20 21C20 20.45 20.45 20 21 20ZM27 28.59C27 26.09 23.03 25.01 21 25.01C18.97 25.01 15 26.09 15 28.59V30H27V28.59ZM17.48 28C18.22 27.5 19.7 27 21 27C22.3 27 23.77 27.49 24.52 28H17.48Z"
                      fill="#FCFCFD"
                  />
                </svg>
                <p class="mt-3 ml-2">{{$t('KYC_steps.btn_submit')}}</p>
              </div>
              <div class="col-md-4">
                <svg
                    class="my-4"
                    width="120"
                    height="24"
                    viewBox="0 0 96 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM95.0607 13.0607C95.6464 12.4749 95.6464 11.5251 95.0607 10.9393L85.5147 1.3934C84.9289 0.807611 83.9792 0.807611 83.3934 1.3934C82.8076 1.97919 82.8076 2.92893 83.3934 3.51472L91.8787 12L83.3934 20.4853C82.8076 21.0711 82.8076 22.0208 83.3934 22.6066C83.9792 23.1924 84.9289 23.1924 85.5147 22.6066L95.0607 13.0607ZM2 13.5H94V10.5H2V13.5Z"
                      fill="#E4E7EC"
                  />
                </svg>

              </div>
              <div class="col-md-4 d-flex align-items-center">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                      width="48"
                      height="48"
                      rx="24"
                      fill="#E4E7EC"
                  />
                  <path
                      d="M31 16H30V14H28V16H20V14H18V16H17C15.9 16 15 16.9 15 18V32C15 33.1 15.9 34 17 34H31C32.1 34 33 33.1 33 32V18C33 16.9 32.1 16 31 16ZM31 32H17V22H31V32ZM17 20V18H31V20H17ZM19 24H29V26H19V24ZM19 28H26V30H19V28Z"
                      fill="#667085"
                  />
                </svg>
                <p class="mt-3 ml-2">{{$t('KYC_steps.lbl_authentication')}}</p>
              </div>
            </div>

            <div class="mt-4">
              <input
                  ref="inputFile"
                  class="d-none"
                  name="upload_cont_img"
                  type="file"
                  accept="image/*"
                  @change="handleFileSelected"
              />
              <div
                  v-for="(form, i) in formData"
                  :key="form.code"
              >
                <label class="fs-16 fw-600 form-label required mt-2">{{ form.name }}</label>
                <input
                    type="text"
                    class="form-control form-input my-2 fs-16 fw-500 text-grey-600 bg-white"
                    id="txt-position"
                    :placeholder="$t('KYC_steps.lbl_enter_number_plh')"
                    autocomplete="off"
                    v-model="form.front_cert_text"
                />

                <div class="row">
                  <!-- CCCMT -->
                  <div class="col-md-6 mt-2">
                    <div class="d-flex">
                      <label class="form-label required mb-2 fs-16 fw-600">{{$t('KYC_steps.lbl_front_photo')}}</label>
                      <svg
                          class="ml-auto"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#667085"
                        />
                      </svg>
                    </div>
                    <div @click="uploadFile('front_cert', i)">
                      <div
                          v-if="!form.front_cert_url"
                          class="bg-left d-flex justify-content-center align-items-center"
                      >
                        <div class="custom-file-upload d-flex justify-content-center align-items-center">
                            <span
                                class="bg-pri text-white robo-14-500 ml-4"
                                style="
                          padding: 13px 16px;
                          cursor: pointer;
                          border-radius: 10px;
                          "
                            >{{$t('KYC_steps.btn_upload_photos')}}</span>
                        </div>
                      </div>
                      <div
                          v-else
                          class="bg-left d-flex justify-content-center align-items-center"
                          :style="`background-image: url('${form.front_cert_url}')`"
                      >
                        <div class="custom-file-upload d-flex justify-content-center align-items-center">
                            <span
                                class="bg-pri text-white robo-14-500 ml-4"
                                style="
                          padding: 13px 16px;
                          cursor: pointer;
                          border-radius: 10px;
                          "
                            >{{$t('KYC_steps.btn_upload_photos')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- CCCMS -->
                  <div class="col-md-6 mt-2">
                    <div class="d-flex">
                      <label class="form-label mb-2 fs-16 fw-600">{{$t('KYC_steps.lbl_end_photo')}}</label>
                      <svg
                          class="ml-auto"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#667085"
                        />
                      </svg>
                    </div>
                    <div @click="uploadFile('back_cert', i)">
                      <div
                          v-if="!form.back_cert_url"
                          class="bg-right d-flex justify-content-center align-items-center"
                      >
                        <div class="custom-file-upload d-flex justify-content-center align-items-center">
                            <span
                                class="bg-pri text-white robo-14-500 ml-4"
                                style="
                          padding: 13px 16px;
                          cursor: pointer;
                          border-radius: 10px;
                          "
                            >{{$t('KYC_steps.btn_upload_photos')}}</span>
                        </div>
                      </div>
                      <div
                          v-else
                          class="bg-right d-flex justify-content-center align-items-center"
                          :style="`background-image: url('${form.back_cert_url}')`"
                      >
                        <div class="custom-file-upload d-flex justify-content-center align-items-center">
                            <span
                                class="bg-pri text-white robo-14-500 ml-4"
                                style="
                          padding: 13px 16px;
                          cursor: pointer;
                          border-radius: 10px;
                          "
                            >{{$t('KYC_steps.btn_upload_photos')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- //CCCDMS -->

            <div class="mt-4 mb-2">
              <button class="btn bg-pri bd-pri text-white btn-l w-100" :disabled="loading">
                {{$t('KYC_steps.btn_submit')}}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DoctorKyc',
  data () {
    return {
      loading: false,
      formData: [],
      certRequires: [],
      currentField: '',
      currentIndex: ''
    }
  },
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    }
  },
  mounted () {
    this.getCertRequires()
  },
  methods: {
    show () {
      this.getCertRequires()
      window.$('#modalVerify').modal('show')
    },
    close () {
      window.$('#modalVerify').modal('hide')
    },
    async getCertRequires () {
      this.loading = true
      try {
        this.formData = []
        const data = []
        const [res1, res2] = await Promise.all([
          this.DoctorRequest.getKycCerts(),
          this.DoctorRequest.getCertRequires({
            p_type: this.$user?.doctor?.type ? this.$user?.doctor?.type : 0
          })
        ])
        const kycCerts = res1.data.data
        this.certRequires = res2.data.data
        _.forEach(this.certRequires, (certRequire, i) => {
          const existedKyc = _.find(kycCerts, ['cert_type_code', certRequire.cert_type_code])
          data.push({
            id: existedKyc?.id,
            name: certRequire.cert_type.name,
            cert_type_code: certRequire.cert_type_code,
            front_cert_text: existedKyc?.front_cert_text,
            front_cert_file: null,
            front_cert_url: existedKyc?.front_cert_url,
            back_cert_text: existedKyc?.back_cert_text,
            back_cert_file: null,
            back_cert_url: existedKyc?.back_cert_url
          })
        })
        this.formData = data
      } finally {
        this.loading = false
      }
    },
    async onUpdateKyc () {
      this.loading = true
      try {
        let isCreateNew = false
        const formData = new FormData()
        this.formData.forEach((item, index) => {
          // if (item.id) {
          //   const formUpdate = new FormData()
          //   _.forEach(item, (value, key) => {
          //     value && formUpdate.append(key, value)
          //   })
          //   this.DoctorRequest.updateKyc(item.id, formUpdate)
          //   return
          // }

          isCreateNew = true
          _.forEach(item, (value, key) => {
            value && formData.append(`certs[${index}][${key}]`, value)
          })
        })

        if (isCreateNew) {
          await this.DoctorRequest.createKyc(formData)
        }
        this.$emit('refresh')
        setTimeout(() => {
          this.close()
          this.$emit('showCalendar')
        }, 399)
      } finally {
        this.loading = false
      }
    },
    uploadFile (field, index) {
      this.currentField = field
      this.currentIndex = index
      this.$refs.inputFile.click()
    },
    handleFileSelected (evt) {
      const file = evt.target.files[0]
      const {currentField, currentIndex} = this
      this.$set(this.formData, currentIndex, {
        ...this.formData[currentIndex],
        [currentField + '_url']: URL.createObjectURL(file),
        [currentField + '_file']: file
      })
      evt.target.value = null
    }
  }
}
</script>

<style scoped>
.bg-left {
  background-image: url("../../public/assets/images/Rectangle-98.svg");
  height: 160px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-right {
  background-image: url("../../public/assets/images/rectangle-right.svg");
  height: 160px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.form-input {
  color: #1d2939;
  border: 0.1px solid #98a2b3;
  padding: 13px;
  border-radius: 12px;
}
input:checked + .slider {
  background-color: #20409b;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
input:checked + .active:before {
  transform: translateX(20px);
}
.border-20px {
  border-radius: 20px;
}
</style>