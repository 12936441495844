<template>
  <div class="doctor-info">
    <div v-if="appUtils.isDoctor()">
      <div class="text-left mb-3 d-flex align-items-center">
        <span
          class="fs-20 fw-700 txt-grey-900 mr-2"
        >{{$t('personal_settings.lbl_personal_information')}}</span>
        <div v-if="form.is_verified === 2 || form.is_verified === 3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 2L6.01 8L10 12L6.01 16.01L6 22H18V16L14 12L18 8.01V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5Z"
              fill="#DC6803"
            />
          </svg>
          <span class="fs-14" style="color: #dc6803">{{$t('personal_settings.lbl_wait_verify')}}</span>
        </div>
        <div v-if="form.is_verified === 4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
              fill="#027A48"
            />
          </svg>

          <span class="fs-14" style="color: #027a48">{{$t('personal_settings.lbl_verified')}}</span>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 d-flex align-items-center mb-3">
          <!-- {{form}} -->
          <!-- {{this.form.user.avatar}}
          {{checkavatar() && !thumb_file}}-->
          <!-- <div
            v-if="checkavatar() && !thumb_file"
            style="
              width: 100px;
              height: 100px;
              border-radius: 50px;
              background-size: 100px;
              background-position: center;
            "
            :style="`background-image: url(${avatar})`"
          ></div>-->
          <ImageBox
            class="w-28 h-28 bg-gray-200 border rounded-full object-cover object-top"
            avatar
            :source="avatar"
            :alt="form.name"
            v-if="checkavatar() && !thumb_file"
          />
          <div v-if="!checkavatar() && !thumb_file">
            <div
              class="bg-pri text-white text-center"
              style="
                width: 100px;
                height: 100px;
                border-radius: 50px;
                line-height: 100px;
              "
            >
              <div class="fs-36">{{ form.name ? form.name.charAt(0) : "905" }}</div>
            </div>
          </div>
          <div v-if="thumb_file">
            <div
              class="bg-dark text-white"
              style="
                width: 84px;
                height: 84px;
                border-radius: 42px;
                background-size: 84px;
                background-position: center;
              "
              :style="`background-image: url('${getBase64URL(thumb_file)}')`"
            ></div>
          </div>

          <div class="ml-2">
            <form>
              <label for="file-upload" class="custom-file-upload">
                <span
                  class="bg-pri text-white robo-14-500 ml-2"
                  style="padding: 6px 8px; cursor: pointer; border-radius: 8px"
                >{{$t('personal_settings.btn_change')}}</span>
              </label>
              <input
                id="file-upload"
                class="d-none"
                name="upload_cont_img"
                type="file"
                accept="image/*"
                @change="onSelectedThumbFile($event)"
              />
            </form>
          </div>
        </div>
        <!-- {{form}} -->
        <div class="col-md-6">
          <div v-if="form.is_verified === null || form.is_verified===0">
            <div class="h134 text-center p-3 d-flex flex-column justify-content-between w-100">
              <p
                class="fs-18 fw-600 text-white mb-0 text-left mb-1"
              >{{$t('personal_settings.lbl_unverified ')}}</p>
              <p
                class="fs-14 fw-500 text-pri text-left mb-3"
              >{{ $t('personal_settings.lbl_msg_only_ws')[$orgModel] }}</p>
              <button @click="showVerify" class="w-100 btn p-2">
                <span class="fs-14 fw-600 txt-pri">{{$t('personal_settings.btn_verify_now')}}</span>
              </button>
            </div>
          </div>

          <div v-if="form.is_verified === 1">
            <div class="h134 text-center p-3 d-flex flex-column justify-content-between w-100">
              <p
                class="fs-18 fw-600 text-white mb-0 text-left mb-1"
              >{{$t('personal_settings.lbl_request_kyc_msg')}}</p>
              <button @click="showCalendar" class="w-100 btn p-2">
                <span class="fs-14 fw-600 txt-pri">{{$t('personal_settings.lbl_book_appt')}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="row">
          <div class="col-sm-6">
            <label for="txt-name" class="fs-16 fw-600">{{$t('personal_settings.lbl_fullname')}}</label>
            <input
              type="text"
              class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
              id="txt-name"
              v-model="form.name"
              :placeholder="$t('personal_settings.lbl_enter_fullname')"
              autocomplete="off"
            />
            <small class="form-text text-warning" v-if="!form.name">
              {{
              err.name
              }}
            </small>
          </div>
          <div class="col-sm-6">
            <label for="txt-phone" class="fs-16 fw-600">{{$t('personal_settings.lbl_phone_number')}}</label>
            <input
              type="text"
              class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
              v-model="form.phone"
              id="txt-phone"
              :placeholder="$t('personal_settings.lbl_phone_number')"
              autocomplete="off"
            />
            <small class="form-text text-warning" v-if="!form.phone">
              {{
              err.phone
              }}
            </small>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-6">
            <label
              for="txt-name"
              class="fs-16 fw-600"
            >{{$t('personal_settings.lbl_prominent_sector ')}}</label>
            <input
              type="text"
              class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
              id="txt-name"
              v-model="form.majors"
              :placeholder="$t('personal_settings.lbl_prominent_sector ')"
              autocomplete="off"
            />
            <small class="form-text text-warning" v-if="!form.majors">
              {{
              err.majors
              }}
            </small>
          </div>
          <div class="col-sm-6">
            <label for="txt-phone" class="fs-16 fw-600">{{$t('personal_settings.lbl_address')}}</label>
            <input
              type="text"
              class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
              v-model="form.address"
              id="txt-phone"
              :placeholder="$t('personal_settings.lbl_address')"
              autocomplete="off"
            />
            <small class="form-text text-warning" v-if="!form.address">
              {{
              err.address
              }}
            </small>
          </div>
        </div>

        <div class="mt-3">
          <label for="txt-phone" class="fs-16 fw-600">{{$t('personal_settings.lbl_introduce')}}</label>
          <input
            type="text"
            class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
            v-model="form.gioi_thieu"
            id="txt-phone"
            :placeholder="$t('personal_settings.lbl_introduce')"
            autocomplete="off"
          />
          <small class="form-text text-warning" v-if="!form.gioi_thieu">
            {{
            err.gioi_thieu
            }}
          </small>
        </div>

        <div class="mt-3">
          <label for="txt-phone" class="fs-16 fw-600 mb-2">{{$t('lbl_job_title')}}</label>
          <select
            v-model="form.type"
            class="form-control form-input bg-white fs-16 fw-500 form-select"
            @change="change()"
          >
            <option :value="0">{{$t('workspace_setting.lbl_doctor')}}</option>
            <option :value="1">{{$t('workspace_setting.lbl_health_care_staff')}}</option>
            <option :value="2">{{$t('workspace_setting.lbl_specialist_doctor')}}</option>
            <option :value="3">{{$t('workspace_setting.lbl_nurse')}}</option>
            <option :value="4">{{$t('workspace_setting.lbl_hcp')}}</option>
          </select>
        </div>

        <div class="row mt-3">
          <div class="col-sm-6">
            <label for="txt-name" class="fs-16 fw-600">Email</label>
            <input
              type="text"
              class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
              id="txt-name"
              :value="form.user && form.user.email"
              placeholder="email"
              autocomplete="off"
            />
            <!-- <small class="form-text text-warning" v-if="!form.user.email">{{
            err.user.email
            }}</small>-->
          </div>
          <div class="col-sm-6 row">
            <label
              for="txt-name"
              class="fs-16 fw-600 col-6"
            >{{$t('personal_settings.lbl_hcp_code')}}</label>

            <div class="col-6">
              <!-- <p
                class="fs-14 fw-500 mb-0 txt-pri text-decoration-underline text-right cursor-pointer"
                @click="showQr(true)"
              >QR code</p>-->
            </div>

            <span
              class="form-control mt-2 fs-16 fw-500 text-grey-600 ml-2 bg-white"
              id="txt-name"
              placeholder
              autocomplete="off"
            >
              <div class="fs-14 fw-500 row">
                <p class="mb-0 col-6 text-left">{{ form.code }}</p>
                <p class="mb-0 col-6 text-right">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="copyCode(form.code)"
                    class="cursor-pointer"
                  >
                    <path
                      d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM19.5 21H8.5V7H19.5V21Z"
                      fill="#1D2939"
                    />
                  </svg>
                </p>
              </div>
            </span>
          </div>
        </div>

        <div class="mt-4">
          <div class="d-flex align-items-center">
            <p class="fs-20 fw-700 txt-grey-900">{{$t('personal_settings.lbl_work')}}</p>

            <p v-if="form.is_verified !== 4" class="txt-red-800 fs-14 fw-500 ml-3">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
                  fill="#B42318"
                />
              </svg>
              {{$t('personal_settings.lbl_verify_account_msg')}}
            </p>
          </div>

          <div class="d-flex">
            <div class="mt-3 mr-3">
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="form.patient_search"
                  true-value="1"
                  false-value="0"
                  :disabled="form.is_verified != 4"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="w-50">
              <p
                class="fs-16 fw-600 txt-grey-900 mb-1"
              >{{$t('personal_settings.chk_allow_patient_sch')}}</p>
              <p class="fs-12 fw-400">{{$t('personal_settings.chk_allow_patient_msg')}}</p>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="mt-3 mr-3">
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="form.doctor_search"
                  true-value="1"
                  false-value="0"
                  :disabled="form.is_verified != 4"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="w-50">
              <p
                class="fs-16 fw-600 txt-grey-900 mb-1"
              >{{$t('personal_settings.chk_allow_doctor_sch')}}</p>
              <p class="fs-12 fw-400">{{ $t('personal_settings.chk_allow_doctor_msg')[$orgModel] }}</p>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="mt-3 mr-3">
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="form.ws_search"
                  true-value="1"
                  false-value="0"
                  :disabled="form.is_verified != 4"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="w-50">
              <p
                class="fs-16 fw-600 txt-grey-900 mb-1"
              >{{$t('personal_settings.chk_allow_facilitie')}}</p>
              <p
                class="fs-12 fw-400"
              >{{ $t('personal_settings.chk_allow_facilitie_msg')[$orgModel] }}</p>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <p class="fs-20 fw-700 txt-grey-900">{{$t('personal_settings.lbl_job_info')}}</p>
        </div>

        <div class="row mt-3">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="txt-work" class="fs-16 fw-600">{{$t('personal_settings.lbl_location')}}</label>
              <input
                type="text"
                class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                v-model="form.work"
                id="txt-work"
                :placeholder="$t('personal_settings.lbl_ex_bvk')"
                autocomplete="off"
              />
              <small class="form-text text-warning" v-if="!form.work">
                {{
                err.work
                }}
              </small>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="txt-position" class="fs-16 fw-600">{{$t('lbl_position')}}</label>
              <input
                type="text"
                class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                v-model="form.chuc_vu"
                id="txt-position"
                :placeholder="$t('personal_settings.lbl_ex_surgery_mng')"
                autocomplete="off"
              />
            </div>
          </div>
        </div>

        <div class="m-0 mt-3" id="tagInputSpcl">
          <div class="form-group">
            <label for="txt-majors" class="fs-16 fw-600">{{$t('personal_settings.lbl_specialist')}}</label>
            <tags-input
              id="tagRemove"
              class="form-input mt-2"
              element-id="tags"
              v-model="tags"
              wrapper-class="w-100 fs-14 fw-500"
              :typeahead="true"
              placeholder
            ></tags-input>
          </div>
        </div>

        <div>
          <div
            class="mb-2 border-bottom fs-16 fw-600"
            style="padding: 24px 0px 9px 9px"
          >{{$t('personal_settings.lbl_work_experience')}}</div>
          <div v-if="list_exps && list_exps.total">
            <div v-for="(item, index) in list_exps.data" :key="index">
              <div
                class="mt-2"
                style="
                  background-color: #f2f5fa;
                  padding: 16px 24px;
                  border-radius: 6px;
                "
              >
                <div class="row">
                  <div class="col-sm-10">
                    <div
                      class="fs-16 fw-600 txt-grey-900"
                    >{{ item.noi_cong_tac ? item.noi_cong_tac : "" }}</div>
                    <div class="fs-14 fw-400 mt-1">
                      <span>{{ item.chuc_vu ? item.chuc_vu : "" }}</span>
                      <span class="border-left pl-1">
                        {{ item.start_year ? item.start_year : "" }} -
                        {{ item.end_year ? item.end_year : " " }}
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-2 d-flex align-items-center justify-content-end">
                    <div class="icon-profile-edit" @click="editExps(index)"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <WorkExperience
                  v-if="is_show_ex_type == index"
                  :is_edit="true"
                  :exp="item"
                  @onBackEx="onBackEx"
                  @onBackExEdit="onBackExEdit"
                  @onSubmitExps="onSubmitExps"
                ></WorkExperience>
              </div>
            </div>
          </div>
          <!-- {{is_show_ex}} -->
          <WorkExperience
            v-if="is_show_ex"
            :is_edit="false"
            @onBackEx="onBackEx"
            @onSubmitExps="onSubmitExps"
          ></WorkExperience>
          <div class="mt-2">
            <button
              class="w-100 btn bg-lavender border-0"
              style="padding: 3px 16px 3px 16px; border-radius: 10px"
              href="javascript:void(0)"
              v-if="!is_show_ex"
              @click="showEx(true)"
            >
              <p class="fs-14 fw-600 txt-pri d-flex align-items-center mt-3">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1"
                >
                  <path
                    d="M9.83317 4.83366H8.1665V8.16699H4.83317V9.83366H8.1665V13.167H9.83317V9.83366H13.1665V8.16699H9.83317V4.83366ZM8.99984 0.666992C4.40817 0.666992 0.666504 4.40866 0.666504 9.00033C0.666504 13.592 4.40817 17.3337 8.99984 17.3337C13.5915 17.3337 17.3332 13.592 17.3332 9.00033C17.3332 4.40866 13.5915 0.666992 8.99984 0.666992ZM8.99984 15.667C5.32484 15.667 2.33317 12.6753 2.33317 9.00033C2.33317 5.32533 5.32484 2.33366 8.99984 2.33366C12.6748 2.33366 15.6665 5.32533 15.6665 9.00033C15.6665 12.6753 12.6748 15.667 8.99984 15.667Z"
                    fill="#20419B"
                  />
                </svg>
                {{$t('personal_settings.btn_add')}}
              </p>
            </button>
          </div>
        </div>
        <div
          class="mb-2 border-bottom fs-16 fw-600"
          style="padding: 24px 0px 9px 9px"
        >{{$t('personal_settings.lbl_education')}}</div>
        <div v-if="list_process && list_process.total">
          <div v-for="(item, index) in list_process.data" :key="index">
            <div
              class="mt-2"
              style="
                background-color: #f2f5fa;
                padding: 16px 24px;
                border-radius: 6px;
              "
            >
              <div class="row">
                <div class="col-sm-10">
                  <div
                    class="fs-16 fw-600 txt-grey-900"
                  >{{ item.noi_tu_nghiep ? item.noi_tu_nghiep : "" }}</div>
                  <div class="fs-14 fw-400 mt-1">
                    <span>{{ item.chuyen_nganh ? item.chuyen_nganh : "" }}</span>
                    <span class="border-left pl-1">
                      {{ item.start_year ? item.start_year : "" }} -
                      {{ item.end_year ? item.end_year : " " }}
                    </span>
                  </div>
                </div>
                <div class="col-sm-2 d-flex align-items-center justify-content-end">
                  <div class="icon-profile-edit" @click="editProcess(index)"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <DoctorProcess
                :is_edit="true"
                v-if="is_show_process_type == index"
                @onBackProcess="onBackProcess"
                :process="item"
                @onSubmitProcess="onSubmitProcess"
                @onBackProcessEdit="onBackProcessEdit"
              ></DoctorProcess>
            </div>
          </div>
        </div>
        <DoctorProcess
          :is_edit="false"
          v-if="is_show_process"
          @onBackProcess="onBackProcess"
          @onSubmitProcess="onSubmitProcess"
        ></DoctorProcess>
        <div class="mt-2">
          <button
            class="btn w-100 bg-lavender border-0"
            style="padding: 3px 16px 3px 16px; border-radius: 10px"
            href="javascript:void(0)"
            v-if="!is_show_process"
            @click="showProcess(true)"
          >
            <p class="fs-14 fw-600 txt-pri d-flex align-items-center mt-3">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-1"
              >
                <path
                  d="M9.83317 4.83366H8.1665V8.16699H4.83317V9.83366H8.1665V13.167H9.83317V9.83366H13.1665V8.16699H9.83317V4.83366ZM8.99984 0.666992C4.40817 0.666992 0.666504 4.40866 0.666504 9.00033C0.666504 13.592 4.40817 17.3337 8.99984 17.3337C13.5915 17.3337 17.3332 13.592 17.3332 9.00033C17.3332 4.40866 13.5915 0.666992 8.99984 0.666992ZM8.99984 15.667C5.32484 15.667 2.33317 12.6753 2.33317 9.00033C2.33317 5.32533 5.32484 2.33366 8.99984 2.33366C12.6748 2.33366 15.6665 5.32533 15.6665 9.00033C15.6665 12.6753 12.6748 15.667 8.99984 15.667Z"
                  fill="#20419B"
                />
              </svg>
              {{$t('personal_settings.btn_add')}}
            </p>
          </button>
        </div>
      </div>
      <div align="right" class="py-4">
        <button class="btn bg-pri bd-pri text-white btn-l" @click="onSubmit" :disabled="loading">
          {{$t('personal_settings.btn_update')}}
          <span
            class="spinner-border ml-2"
            role="status"
            v-show="loading"
          >
            <span class="sr-only">Loading...</span>
          </span>
        </button>
      </div>

      <div
        class="modal fade"
        id="modalQrcode"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalQrcodeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-qr modal-dialog-centered" role="document">
          <div class="modal-content pb-3">
            <p class="text-center mt-3">
              <span class="fs-20 fw-700 txt-grey-900">QR Code</span>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </p>
            <qrcode
              v-if="form.code"
              :value="form.code"
              :options="{
                width: 280,
                margin: 1,
              }"
              style="margin: 0 auto; padding: 6px"
              class
              :text="form.code"
            ></qrcode>
          </div>
        </div>
      </div>

      <DoctorKyc @refresh="refresh" @showCalendar="showCalendar" ref="doctorKyc" />
      <CalendarKyc @refresh="refresh" ref="calendarKyc" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import appUtils from '../utils/appUtils'
import DoctorStatsAppointment from '../components/DoctorStatsAppointment.vue'
import DoctorProcess from '../components/Doctor/DoctorProcess'
import WorkExperience from '../components/Doctor/WorkExperience'
import DoctorKyc from './DoctorKyc'
import CalendarKyc from './CalendarKyc'
import ImageBox from '@/components/Common/Image.vue'

export default {
  name: 'DoctorInfo',
  components: {
    DoctorStatsAppointment,
    DoctorProcess,
    WorkExperience,
    DoctorKyc,
    CalendarKyc,
    ImageBox
  },
  data () {
    return {
      appUtils,
      loading: false,
      qr_code: '',
      is_show_ex: false,
      is_show_process: false,
      doctor: '',
      specialists: '',
      tags: [],
      form: {
        name: '',
        phone: '',
        address: '',
        open_time: '',
        close_time: '',
        // certificate: '',
        majors: [],
        work: '',
        gioi_thieu: '',
        chuc_vu: '',
        is_verified: '',
        ws_search: null,
        type: 0
      },
      err: {
        name: '',
        phone: '',
        address: '',
        open_time: '',
        close_time: '',
        // certificate: '',
        majors: '',
        work: ''
      },
      avatar: '',
      thumb_file: '',
      list_exps: null,
      list_process: null,
      is_show_ex_type: -1,
      is_show_process_type: -1,
      clinic: ''
      // certificate: '',
    }
  },
  watch: {
    tags (s) {
      console.log(s)
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.getDetail()
      this.getEpxs()
      this.getProcess()
      this.getSpecialistsDetail()
      this.$refs.doctorKyc.getCertRequires()
      this.$refs.calendarKyc.getDoctorKYC()
    },
    async getDetail () {
      let self = this
      let resp = await self.$rf.getRequest('DoctorRequest').getDoctorMe()
      const { data } = resp
      self.form = data
      self.form.name = data.user.name
      self.qr_code = data.qr_code
      // self.certificate = data.certificate
      if (data.user.avatar) {
        self.avatar = appUtils.getImageURL(data.user.avatar)
      }
    },
    checkavatar () {
      return this.form && this.form.user && this.form.user.avatar
    },
    async getEpxs () {
      try {
        let self = this
        const user = this.$user
        const params = {
          sort: 'desc',
          sort_by: 'start_year',
          doctor_id: user && user.doctor && user.doctor.id
        }
        const result = await self.$rf
          .getRequest('DoctorRequest')
          .getDoctorExps(params)
        self.list_exps = result.data
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async getProcess () {
      try {
        let self = this
        const user = this.$user
        const params = {
          sort: 'desc',
          sort_by: 'start_year',
          doctor_id: user && user.doctor && user.doctor.id
        }
        const result = await self.$rf
          .getRequest('DoctorRequest')
          .getDoctorEdus(params)
        self.list_process = result.data
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    editExps (index) {
      let self = this
      self.is_show_ex_type = index
    },
    editProcess (index) {
      let self = this
      self.is_show_process_type = index
    },
    showProcess (type) {
      let self = this
      self.is_show_process = type
    },
    onBackProcess (type) {
      let self = this
      self.showProcess(type)
    },
    onBackExEdit (type) {
      let self = this
      if (!type) {
        self.is_show_ex_type = -1
      }
    },
    onBackProcessEdit (type) {
      let self = this
      if (!type) {
        self.is_show_process_type = -1
      }
    },
    onBackEx (type) {
      let self = this
      self.showEx(type)
    },
    showEx (type) {
      let self = this
      self.is_show_ex = type
    },
    async onSubmitExps (data, is_edit) {
      try {
        if (!is_edit) {
          await this.$rf.getRequest('DoctorRequest').postDoctorExps(data)
          this.$toast.clear()
          this.getEpxs()
          this.$toast.open({
            message: this.$t('multidisciplinary_board.lbl_added'),
            type: 'success'
          })
          this.showEx(false)
        } else {
          await this.$rf
            .getRequest('DoctorRequest')
            .postDoctorExpsEdit(data.id, data)
          this.$toast.clear()
          this.getEpxs()
          this.$toast.open({
            message: this.$t('workspace_setting.lbl_update_succecss'),
            type: 'success'
          })
          this.onBackExEdit(false)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async onSubmitProcess (data, is_edit) {
      try {
        if (!is_edit) {
          await this.$rf.getRequest('DoctorRequest').postDoctorEdus(data)
          this.$toast.clear()
          this.getProcess()
          this.$toast.open({
            message: this.$t('multidisciplinary_board.lbl_added'),
            type: 'success'
          })
          this.showProcess(false)
        } else {
          await this.$rf
            .getRequest('DoctorRequest')
            .postDoctorEdusEdit(data.id, data)
          this.$toast.clear()
          this.getProcess()
          this.$toast.open({
            message: this.$t('workspace_setting.lbl_update_succecss'),
            type: 'success'
          })
          this.onBackProcessEdit(false)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async onSubmit () {
      let self = this
      if (!self.validateForm()) {
        return false
      }
      try {
        this.loading = true
        await this.updateInfo()
        if (this.tags.findIndex(t => !t.id) > -1) {
          await this.CreateSpecialists()
        }
        self.refresh()

        await self.$rf
          .getRequest('AuthRequest')
          .me()
          .then(resp => {
            if (resp) {
              appUtils.setLocalUser(resp.data)
              if (self.$route.query.redirect) {
                let path = self.$route.query.redirect
                self.$router.push({ path: path })
              }
              self.$toast.open({
                message: this.$t('workspace_setting.lbl_update_succecss'),
                type: 'success'
              })
            }
          })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async updateInfo () {
      let self = this
      let data = self.form
      data.avatar = self.thumb_file
      const formData = new FormData()
      for (const key in data) {
        if (key !== 'user' && key !== 'certificate') {
          formData.append(key, _.isNil(data[key]) ? '' : data[key])
        }
      }
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .updateDoctorMe(formData)
        .then(r => {
          return r
        })
      return r
    },
    async getSpecialistsDetail () {
      let self = this
      self.loading = true
      this.tags = []
      try {
        let params = {
          sort: 'created_at',
          sort_by: 'desc'
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getSpecialistsDetail(params)
          .then(res => {
            const { data } = res
            data.forEach(element => {
              self.tags.push({
                key: element.id,
                value: element.name,
                id: element.id
              })
            })
          })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async CreateSpecialists () {
      let self = this
      self.loading = true
      let specialists = []
      this.tags.forEach(t => {
        if (!t.id) {
          specialists.push({
            name: t.value,
            tags: appUtils.removeVietnameseTones(t.value)
          })
        }
      })
      let params = {
        specialists: specialists
      }
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .CreateSpecialists(params)
        .then(r => {
          return r
        })
        .catch(e => {
          console.log(e)
          return false
        })
      if (r.data) {
        let tags = []
        r.data.forEach(t => {
          tags.push(t.id)
        })
        let ts = await this.UpdateSpecialist(tags)
        return ts
      } else {
        return r
      }
    },
    async UpdateSpecialist (tagIds) {
      let self = this
      self.loading = true
      let specialists = [...tagIds]
      this.tags.forEach(t => {
        if (t.id) {
          specialists.push(t.id)
        }
      })
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .UpdateSpecialist({ specialists: specialists })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    validateForm () {
      let self = this
      let form = self.form
      if (!form.name) {
        self.err.name = this.$t('personal_settings.lbl_enter_fullname')
        return false
      } else {
        self.err.name = ''
        self.err.phone = ''
        self.err.address = ''
        self.err.majors = ''
        self.err.work = ''
        return true
      }
    },
    copyInviteCode () {
      const invite_code = document.getElementById('invite_code')
      const range = document.createRange()
      const selection = window.getSelection()
      selection.removeAllRanges()
      range.selectNodeContents(invite_code)
      selection.addRange(range)
      try {
        if (this.isSupport('copy')) {
          document.execCommand('copy')
          this.$toast.open({
            message: this.$t('multidisciplinary_board.lbl_copied_clipboard'),
            type: 'success'
          })
        } else {
          this.$toast.open({
            message: 'Trình duyệt không hỗ trợ',
            type: 'warning'
          })
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    isSupport (cmd) {
      return document.queryCommandSupported(cmd)
    },
    onSelectedThumbFile (event) {
      this.thumb_file = event.target.files[0]
    },
    onSelectedCertificate (event) {
      this.err.certificate = ''
      this.certificate = event.target.files[0]
    },
    getBase64URL (front_cert_file) {
      return URL.createObjectURL(front_cert_file)
    },
    copyCode (code) {
      let el_code = document.createElement('input')
      document.body.appendChild(el_code)
      el_code.value = code
      el_code.select()
      el_code.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('multidisciplinary_board.lbl_copied_clipboard'),
        type: 'success'
      })
      el_code.remove()
    },
    showQr (show) {
      window.$('#modalQrcode').modal(show ? 'show' : 'hide')
    },
    showVerify () {
      this.$refs.doctorKyc.show()
    },
    showCalendar () {
      this.$refs.calendarKyc.show()
    },
    change () {
      return confirm(this.$t('KYC_steps.lbl_note_change_title'))
    }
  }
}
</script>
<style scoped>
.upload-button {
  width: 100%;
  background-color: #f2f4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  border-radius: 16px;
  background-position: center;
}
.icon-profile-edit {
  background-image: url('../../public/assets/images/icon/doctor_profile_edit.svg');
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  cursor: pointer;
}
.form-group {
  width: 100%;
  padding: 0;
}
.h134 {
  width: 287px;
  border-radius: 16px;
  background-color: #20409b;
}
.text-pri {
  color: #c7d3f3;
}
.form-input {
  color: #1d2939;
  border: 0.1px solid #98a2b3;
  padding: 13px;
  border-radius: 12px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #20409b;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

input:checked + .active:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.txt-red-800 {
  color: #b42318;
}
</style>
